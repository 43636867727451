var mainPhoneNumber = "+44 20 3992 9357";
var mainPhoneNumberWithoutDash = "+442039929357";
var mainAddress1 = "52 Grosvenor Gardens,";
var mainAddress2 = "London SW1W 0AU, UK";

$('.phone-number').each(function () {
    $(this).attr('href' , 'tel:'+ mainPhoneNumberWithoutDash);

    if ($(this).html() == '') {
        $(this).text(mainPhoneNumber);
        return;
    }
    else if ($(this).children().attr("class").includes('bi-telephone-fill')) {
        $(this).children('.bi-telephone-fill').after('&nbsp;' + mainPhoneNumber);
    }
});

$('.showAddress').each(function () {
    if ($(this).text() == '') {
        $(this).html(mainAddress1 +'<br/>'+ mainAddress2);
        return;
    }
});

